import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import ChangeText from "src/components/global/typography/changeText"
import typographySizes from "src/assets/styles/typographySizes.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import Divider from "src/components/global/Divider.js"

const StyledLink = styled(Link)`
  font-size: ${typographySizes.xl}px;
  text-decoration: none;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  line-height: 1;
  padding-bottom: 2px;
  text-transform: lowercase;
  width: 100%;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
  }
  /* background-color: red; */
`

const Wrapper = styled.button`
  position: relative;
  z-index: 0;
  transition: all 0.5s;
  width: 100%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 30px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`

const TextWrap = styled.div`
  position: relative;
`

class NewsLink extends React.Component {
  state = { hoverON: false }

  showArrow = () => {
    this.setState({
      hoverON: true,
    })
  }

  hideArrow = () => {
    this.setState({
      hoverON: false,
    })
  }

  render() {
    return (
      <Wrapper
        className={this.state.hoverON ? "show" : ""}
        onMouseEnter={this.showArrow}
        onMouseLeave={this.hideArrow}
      >
        <TextWrap>
          <StyledLink to={`${this.props.link}`}>
            <ChangeText
              hoverText={this.props.hoverText}
              hover={this.state.hoverON}
            >
              {this.props.children}
            </ChangeText>
          </StyledLink>
        </TextWrap>
      </Wrapper>
    )
  }
}

export default NewsLink
