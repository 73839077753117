import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import slugify from "slugify"

import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import SEO from "src/components/global/SEO.js"
import typographySizes from "src/assets/styles/typographySizes.js"
import distances from "src/assets/styles/distances.js"
import noHangers from "src/components/global/fn/noHangers.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import MainBtn from "src/components/global/btn/MainBtn"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import BodyText from "src/components/global/typography/BodyText.js"
import Slider from "src/components/global/Slider"
import Accordion from "src/components/global/Accordion"
// import PersonContact from "src/components/global/PersonContact.js"
// import PositionLink from "src/components/joinus/PositionLink.js"
import PositionLinkNew from "src/components/joinus/PositionLinkNew.js"
import Conditions from "src/components/joinus/Conditions.js"
// import Crop from "src/components/global/Crop"
import Wrapper from "src/components/global/Wrapper"

// import LetsMeetSection from "src/components/global/LetsMeetSection"
import BigTextH3 from "src/components/global/typography/BigTextH3.js"

import MidText from "src/components/global/typography/MidText"

import Divider from "src/components/global/Divider.js"

import PositionList from "src/components/joinus/PositionList.js"

const TopWrapper = styled.div`
  width: 75%;
  position: relative;
  left: 25%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    left: 0%;
  }
`

const StyledWrapper = styled(Wrapper)`
  @media (max-width: ${mediaQuery.tablet}) {
    overflow: hidden;
  }
`

const TopTitle = styled(BigTextH3)`
  margin-bottom: 20px;
`

const TopParagraph = styled(MidText)`
  margin-bottom: 50px;
  margin-top: 20px;
  .marginP {
    p {
      margin-bottom: 50px;
    }
  }
`
const Second_Paragraph = styled(BodyText)`
  width: 77.77%;
  margin-top: 50px;
  .marginP {
    p {
      margin-bottom: 30px;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const OpenPositionsWrapper = styled.div``

const OpenPositionsBottom = styled.div`
  /* margin-left: calc(25% - 52px); */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
  }
`

const ConditionsWrappers = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column-reverse;
  }
`

const ConditionsNav = styled.nav`
  position: sticky;
  top: 100px;
  z-index: 900;
  width: 25%;
  @media (max-width: ${mediaQuery.laptop}) {
    /* position: relative; */
    margin-left: 0%;
    bottom: 0;
    background-color: ${colors.white};
    padding: 20px 0;
    width: calc(100% + ${distances.smallMargin}px);
    overflow-y: scroll;
    margin-top: 20px;
  }
`

const ConditionsNavUl = styled.ul`
  list-style: none;
  display: inline-flex;
  flex-direction: column;
  @media (max-width: ${mediaQuery.laptop}) {
    flex-direction: row;
  }
`

const ConditionsNavBtn = styled.li`
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGrey};
  text-decoration: underline;
  padding: 7.5px;
  text-transform: lowercase;
  width: 100%;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: ${colors.mainColor};
    transition: all 0.5s;
    z-index: -1;
  }

  &:hover::before {
    width: 30%;
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }

  &.active::before {
    width: 100%;
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }

  @media (max-width: ${mediaQuery.laptop}) {
    padding-right: 40px;
  }
`

class Dolacz extends React.Component {
  state = { activeCompany: 0, activeCondition: 0 }

  render() {
    const DataAbout = this.props.data.strapiJoinusPage

    return (
      <>
        <SEO
          title={this.props.data.strapiJoinusPage.SEO?.SEO_Title}
          description={this.props.data.strapiJoinusPage.SEO?.SEO_Description}
          image={this.props.data.strapiJoinusPage.SEO.Seo_Img.publicURL}
        />
        <Navigation
          onlyPl
          navBtnLink={this.props.data.strapiGlobal.Nav_btn_link}
          navBtnText={this.props.data.strapiGlobal.Nav_btn_text}
        />
        <StyledWrapper>
          <TopWrapper>
            {/* <Crop> */}
            <TopTitle>{noHangers(DataAbout.Top_Claim)}</TopTitle>

            <TopParagraph>
              <ReactMarkdown
                className={"marginP"}
                source={noHangers(DataAbout.Top_Paragraph)}
              />
            </TopParagraph>
            <Divider />
            <Second_Paragraph>
              <ReactMarkdown
                className={"marginP"}
                source={noHangers(DataAbout.Second_Paragraph)}
              />
            </Second_Paragraph>
            <MainBtn
              // oneText
              marginTop="50px"
              hoverText={DataAbout.About_Btn.Hover}
              Mobile={DataAbout.About_Btn.Mobile}
              link="/poznajnas/"
            >
              {DataAbout.About_Btn.Main}
            </MainBtn>
            {/* </Crop> */}
          </TopWrapper>
        </StyledWrapper>
        <Slider Gallery={this.props.data.strapiJoinusPage.Slider} />

        <Wrapper id="positions">
          <SmallTitle>{DataAbout.Positions_Title}</SmallTitle>
          <PositionList
            data={this.props.data.allStrapiRecruitmentPositions.edges}
          />
          {/* <OpenPositionsWrapper>
            {this.props.data.allStrapiRecruitmentPositions.edges.map(
              document => (
                <PositionLinkNew
                  hoverText={document.node.company.Title}
                  link={`/dolacz/${slugify(document.node.Name, {
                    lower: true,
                  })}`}
                >
                  {document.node.Name}
                </PositionLinkNew>
              )
            )}
          </OpenPositionsWrapper> */}
          <OpenPositionsBottom>
            <MainBtn
              marginTop="50px"
              hoverText={DataAbout.Positions_Btn.Hover}
              Mobile={DataAbout.Positions_Btn.Mobile}
              link={"/kontakt"}
            >
              {DataAbout.Positions_Btn.Main}
            </MainBtn>
          </OpenPositionsBottom>
        </Wrapper>
        <Accordion
          Title={DataAbout.Condition_Title}
          Structure={
            this.props.data.allStrapiCompanies.edges[0].node
              .Recruitment_Condition
          }
        />
        <Wrapper>
          <ConditionsWrappers>
            {/* <ConditionsNav>
              <ConditionsNavUl>
                {this.props.data.allStrapiCompanies.edges.map(
                  (document, index) => (
                    <ConditionsNavBtn
                      onClick={() =>
                        this.setState({
                          activeCompany: index,
                        })
                      }
                      className={
                        index === this.state.activeCompany ? "active" : ""
                      }
                    >
                      {document.node.Title}
                    </ConditionsNavBtn>
                  )
                )}
              </ConditionsNavUl>
            </ConditionsNav> */}
            {/* {this.props.data.allStrapiCompanies.edges.map((document, index) => (
              <Conditions
                // className={index === this.state.activeCompany ? "active" : ""}
                Active={index === this.state.activeCompany ? true : false}
                Document={document}
              />
            ))} */}
          </ConditionsWrappers>
        </Wrapper>
        <Footer companyData={this.props.data.strapiContactPageN} />
      </>
    )
  }
}

export default Dolacz

export const DolaczQuery = graphql`
  query DolaczQuery {
    strapiGlobal {
      Nav_btn_link
      Nav_btn_text
    }
    strapiJoinusPage {
      SEO {
        SEO_Description
        SEO_Title
        Seo_Img {
          localFile {
            publicURL
          }
        }
      }
      Slider {
        caption
        height
        width
        localFile {
          childImageSharp {
            fluid(traceSVG: { background: "#ffffff", color: "#ffff00" }) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      About_Btn {
        Hover
        Main
        Mobile
      }

      Top_Claim
      Top_Paragraph
      Second_Paragraph
      Positions_Title
      Positions_Btn {
        Hover
        Main
        Mobile
      }
      Condition_Title
    }
    allStrapiRecruitmentPositions {
      edges {
        node {
          Name
          id
          company {
            Title
          }
        }
      }
    }
    allStrapiCompanies(sort: { order: ASC, fields: Order }) {
      edges {
        node {
          Title
          Recruitment_Condition {
            Description
            Title
            id
          }
        }
      }
    }
    strapiContactPageN {
      Company_details_Address
      Company_details_Krs
      Company_details_Name
      Company_details_Nip
      Company_details_Regon
      Company_details_Title
    }
  }
`
